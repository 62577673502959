import * as React from 'react';

import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  Copy,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Heading,
  Help,
  Icon,
  ImageWrapper,
  ITableCol,
  Label,
  Notification,
  Paragraph,
  Popover,
  Spinner,
  Stack,
  Status,
  Table,
  Template,
  TdLink,
  Toast,
  Center,
  summon,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import {groupBy, startCase} from 'lodash-es';
import vodCollectionRoutes from 'routes/programming.routes';
import DeleteConfirmation from 'components/deleteConfirmation';
import {formatDateRange} from 'helpers/formatDateRange';
import {rewriteImageUrl} from 'utils/imageUtils';
import routes from 'routes/content.routes';

import {INestedVodCollectionProps} from '../nestedPropsInterface';
import {useBulkDeleteMutation} from 'features/vodCollections/vodCollectionsApi';
import {useFindByIdQuery as findSeriesById} from 'features/series/seriesApi';
import {useFindByIdQuery as findEpisodeById} from 'features/episodes/episodesApi';

import {TableActions} from 'components/tableActions';
import SeriesList, {ISeriesSearchParams} from 'components/seriesList';
import ReorderDialog from '../components/reorder-dialog';
import {vodEntryMapper} from 'helpers/mapVodCategoryEntries';
import {IVodCategoryTitle, TVodCategoryEntryType} from 'models/vodCategoryEntry';
import {useHighlightItems} from 'views/programming/channel/edit/catalog/hooks/useHighlightItems';

import {TSortDirection} from 'models/generic';
import {
  useLazyFindQuery as useFindQuery,
  useLazyTotalDurationQuery as useTotalDurationQuery,
} from 'features/vodCategoryEntries/vodCategoryEntriesApi';
import {uniqBy, isEmpty} from 'lodash-es';
import {getVodEntriesItemState, IRowStateAndMessage} from 'views/programming/vodCollections/utils';
import {ISeriesListResult} from 'models/series';
import useHandleKeyAction from 'helpers/useHandleKeyAction';
import {IEpisode} from 'models/episodes';
import {formatDateWithTimezone} from 'utils/dateUtils';
import {CapstanDialog} from '../capstan/CapstanDialog';
import _ from 'lodash';

const TABLE_COLUMN_NAME = {
  Order: 'order',
  Title: 'content.name',
  Collections: 'content.vodCategoryEntriesCount',
  'Series Type': 'seriesType',
  Genre: 'content.genre',
  '# of S': 'content.seasonCount',
  '# of E': 'content.episodeCount',
  'Availability Window': 'availability',
} as const;

const DEFAULT_ROWS_PER_PAGE = 250;

interface IVodCollectionProps extends INestedVodCollectionProps {
  onAddTitles: (newTitles: IVodCategoryTitle[], isRemoving?: boolean) => void;
  dirtyFields: any;
  setReplaceExisting: (replace: boolean) => void;
}
interface ITitleDetails {
  ageRange: string;
  title: string;
  seasonCount: number;
  episodeCount: number;
  genre: string;
  subGenre: string;
  isDraft?: boolean;
  category: string;
  subCategory: string;
  availability: string;
  poster169: string;
  vodCatEntries: SchedulingInfo[];
  contentId: string;
  contentType: TVodCategoryEntryType;
  seriesType: string;
  episodeSeriesId: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
};

type SchedulingInfo = {
  order: number;
  collectionName: string;
  published: string;
  vodCollectionId: string;
};

export default ({
  model,
  onAddTitles,
  onBlur,
  onChange,
  setFields,
  setReplaceExisting,
}: IVodCollectionProps): JSX.Element => {
  const [deleteVODBulk, {isLoading: isDeleting}] = useBulkDeleteMutation();
  const [getEntries, {data: vodEntries, isFetching: isFetchingEntries}] = useFindQuery();
  const [getTotalDuration, {data: vodTotalDuration}] = useTotalDurationQuery();

  const {ableTo, permissions} = useAppPermissions();
  const CAN_EDIT = ableTo('VOD_EDIT');

  const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>('Order');
  const [sortDir, setSortDir] = React.useState<TSortDirection>('asc');
  const [lazyLoading, setLazyLoading] = React.useState<boolean>(false);
  const [offset, setOffset] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = React.useState(false);
  const [isCapstanDialogOpen, setIsCapstanDialogOpen] = React.useState(false);
  const [hasReordered, setHasReordered] = React.useState(false);
  const [isAddTitleOpen, setIsAddTitleOpen] = React.useState(false);
  const [isTitleDrawerOpen, setIsTitleDrawerOpen] = React.useState(false);
  const [isRemoveTitleOpen, setIsRemoveTitleOpen] = React.useState(false);
  const [isRemoveTitleMultipleOpen, setIsRemoveTitleMultipleOpen] = React.useState(false);

  const [titleInfo, setTitleInfo] = React.useState<'details' | 'scheduling'>('details');

  const [selectedSeriesIds] = React.useState<Set<string>>(new Set());
  const [selectedMovieIds] = React.useState<Set<string>>(new Set());
  const [selectedDraftIds, setSelectedDraftIds] = React.useState<string[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<ISeriesListResult[] | IVodCategoryTitle[]>([]);
  const [drafts, setDrafts] = React.useState<IVodCategoryTitle[]>([]);

  const [curHighlight, setCurHighlight] = React.useState<Partial<ITitleDetails> | undefined>(undefined);
  const [entryScheduling, setEntryScheduling] = React.useState<{episodeId: string; seriesId: string}>({
    episodeId: '',
    seriesId: '',
  });
  const [vodEntriesStateAndMessage, setVodEntriesStateAndMessage] = React.useState<IRowStateAndMessage[]>([]);
  const [sortedVodEntries, setSortedVodEntries] = React.useState<IVodCategoryTitle[] | undefined>([]);
  const [isRemoveAllowed, setIsRemoveAllowed] = React.useState(false);
  const [totalDuration, setTotalDuration] = React.useState<number>(0);

  const {items: selectedItemAdded, add: setSelectedItemAdded} = useHighlightItems();

  const [seriesSearchParams, setSeriesSearchParams] = React.useState<ISeriesSearchParams>({
    isSearchExpanded: true,
    searchParams: {
      published: true,
      sortCol: 'createdAt' as keyof typeof TABLE_COLUMN_NAME,
      sortDir: 'dsc',
    } as any,
    page: 0,
    rowsPerPage: 25,
  });

  const {
    data: episode,
    error: episodeError,
    isFetching: isEpisodeFetching,
  } = findEpisodeById(entryScheduling?.episodeId, {
    refetchOnMountOrArgChange: true,
    skip: !entryScheduling.episodeId,
  });

  const {
    data: series,
    error: seriesError,
    isFetching: isSeriesFetching,
  } = findSeriesById(entryScheduling?.seriesId, {
    refetchOnMountOrArgChange: true,
    skip: !entryScheduling.seriesId,
  });

  const fetchEntries = React.useCallback(
    offsetValue => {
      getEntries({
        id: model.id!,
        offset: offsetValue,
        limit,
        sort: `${TABLE_COLUMN_NAME[sortCol]}:${sortDir}`,
      });
    },
    [limit, getEntries, model.id, sortCol, sortDir],
  );

  const sortTableData = React.useCallback(
    (
      data: IVodCategoryTitle[],
      sortCol: keyof typeof TABLE_COLUMN_NAME,
      sortDir: 'asc' | 'dsc',
    ): IVodCategoryTitle[] => {
      if (TABLE_COLUMN_NAME[sortCol] === 'seriesType') {
        // As it is not an actual field on API
        data.sort((a, b) => {
          const colA = a[TABLE_COLUMN_NAME[sortCol]];
          const colB = b[TABLE_COLUMN_NAME[sortCol]];

          if (colA === colB) {
            return 0;
          }

          return sortDir === 'asc' ? (colA! < colB! ? -1 : 1) : colA! > colB! ? -1 : 1;
        });
      }

      if (vodEntries?.metadata?.offset === 0) {
        setSortedVodEntries(data);
      } else {
        setSortedVodEntries(old => uniqBy(old?.concat(data), 'id'));
      }
      return data;
    },
    [vodEntries?.metadata?.offset],
  );

  // Used to remove highlight and collapse right tray, when user hit 'Esc'
  const {keyPressed: keyEscPressed, setKeyPressed: setKeyEscPressed} = useHandleKeyAction('Escape');

  React.useEffect(() => {
    if (keyEscPressed) {
      setIsTitleDrawerOpen(false);
    }

    setKeyEscPressed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyEscPressed]);

  React.useEffect(() => {
    if (model.id) {
      fetchEntries(offset);
    }
  }, [fetchEntries, model.id, offset]);

  React.useEffect(() => {
    if (model.id) {
      getTotalDuration({id: model.id});
    }
  }, [getTotalDuration, model.id]);

  React.useEffect(() => {
    setTotalDuration(vodTotalDuration?.duration || 0);
  }, [vodTotalDuration?.duration]);

  React.useEffect(() => {
    if (hasReordered) {
      fetchEntries(0);
      setHasReordered(false);
    }
  }, [fetchEntries, hasReordered]);

  React.useEffect(() => {
    if (episodeError) {
      Toast.error('Error', (episodeError as any).data.message);
    } else if (seriesError) {
      Toast.error('Error', (seriesError as any).data.message);
    }
  }, [episodeError, seriesError]);

  React.useEffect(() => {
    sortTableData([...drafts, ...vodEntryMapper(vodEntries?.data?.documents)], sortCol, sortDir);
    if (drafts) {
      setDrafts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCol, sortDir, sortTableData, vodEntries?.data?.documents]);

  React.useEffect(() => {
    setLazyLoading(isFetchingEntries);
  }, [isFetchingEntries]);

  React.useEffect(() => {
    setCurHighlight(prevCurHighlight => ({
      ...prevCurHighlight,
      vodCatEntries: series?.vodCategoryEntries?.map(obj => ({
        vodCollectionId: obj?.vodCategory?.id,
        order: obj?.order || 0,
        collectionName: obj?.vodCategory?.name,
        published: obj?.vodCategory?.enabled ? 'Published' : 'Unpublished',
      })),
    }));
  }, [series?.vodCategoryEntries]);

  React.useEffect(() => {
    setCurHighlight(prevCurHighlight => ({
      ...prevCurHighlight,
      vodCatEntries: episode?.vodCategoryEntries?.map(obj => ({
        vodCollectionId: obj?.vodCategory?.id,
        order: obj?.order || 0,
        collectionName: obj?.vodCategory?.name,
        published: obj?.vodCategory?.enabled ? 'Published' : 'Unpublished',
      })),
    }));
  }, [episode?.vodCategoryEntries]);

  const onLazyLoad = React.useCallback(() => {
    if ((vodEntries?.metadata?.totalCount || 0) > DEFAULT_ROWS_PER_PAGE) {
      setLazyLoading(true);
      setTimeout(() => {
        if ((vodEntries?.metadata?.offset || 0) < (vodEntries?.metadata?.totalCount || 0)) {
          setOffset((vodEntries?.metadata?.offset || 0) + DEFAULT_ROWS_PER_PAGE);
        }
      }, 500);
      setLazyLoading(false);
    }
  }, [vodEntries?.metadata?.offset, vodEntries?.metadata?.totalCount]);

  const handleRemove = (vodEntry?) => {
    if (vodEntry) {
      if (vodEntry?.isDraft) {
        const entries = sortedVodEntries?.filter(e => e.contentId !== (vodEntry.contentId as string));
        setSortedVodEntries(entries!);
        if (onAddTitles) {
          const drafts = entries?.filter(e => e?.isDraft);
          onAddTitles(drafts!, true);
        }
        return;
      } else {
        // other single entry getting deleted? keep drafts
        const drafts = sortedVodEntries?.filter(e => e?.isDraft);
        setDrafts(drafts!);
      }
    }

    if (selectedDraftIds.length > 0) {
      const entries = sortedVodEntries?.filter(e => !selectedDraftIds.includes(e.contentId));
      if (selectedSeriesIds.size < 1 && selectedMovieIds.size < 1) {
        // if only drafs were removed
        setSortedVodEntries(entries!);
        setIsRemoveAllowed(false);
      }
      if (onAddTitles) {
        const drafts = entries?.filter(e => e?.isDraft);
        onAddTitles(drafts!, true);
      }
      setSelectedDraftIds([]);
    }

    if (model.id) {
      if (vodEntry) {
        const series: string[] = [];
        const movies: string[] = [];
        const vodId = vodEntry.contentId;
        vodEntry.contentType === 'series' || vodEntry?.seriesType === 'TV' ? series.push(vodId) : movies.push(vodId);
        callBulkDelete(model.id, series, movies);
      } else {
        if (selectedSeriesIds.size > 0 || selectedMovieIds.size > 0) {
          // one or more existing entries getting deleted? keep drafts
          const drafts = sortedVodEntries?.filter(e => !selectedDraftIds.includes(e.contentId) && e?.isDraft);
          setDrafts(drafts!);
          callBulkDelete(model.id, [...selectedSeriesIds], [...selectedMovieIds]);
        }
      }
    }
  };

  function setTwoDigits(number) {
    if (number < 10) {
      return `0` + number;
    }
    return number;
  }

  const calculateTotalDuration = totalDuration => {
    const hours = Math.floor(totalDuration / 3600);
    const minutes = Math.floor((totalDuration % 3600) / 60);
    const seconds = totalDuration % 60;
    return `${setTwoDigits(hours)}:${setTwoDigits(minutes)}:${setTwoDigits(seconds)}`;
  };

  const changeSort = colname => {
    setLimit(sortedVodEntries?.length || 0);
    setSortedVodEntries([]);
    setOffset(0);
    setSortCol(colname);
    setSortDir((prevSortDir: TSortDirection) => (prevSortDir === 'asc' ? 'dsc' : 'asc'));
  };

  const handleSelect = (rows: ISeriesListResult[]) => {
    setSelectedRow(rows);
  };

  const searchEpisodes = async (ids: string[]): Promise<{data: IEpisode[]} | Error> => {
    return summon
      .post<
        {
          ids: string[];
          fromSeries: boolean;
        },
        {data: IEpisode[]}
      >(`episodes/bulk`, {
        ids,
        fromSeries: true,
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  /*
  Select the right poster for Film or Series
  Films: 1st episode poster image
  Series: the series poster image
  */
  const selectImageUrl = (item, episodesDic) => {
    if (item.type === 'film' && episodesDic[item._id]) {
      return (episodesDic[item._id][0] as any)?.poster.path;
    } else if (item.type === 'tv' && item.poster && item.poster.path !== '') {
      return item.poster.path;
    }

    return 'http://pluto.tv.s3.amazonaws.com/assets/images/default/series.id-thumbnail-default.jpg';
  };

  const handleAddToCollection = async () => {
    try {
      // for type === 'film', we get the episode related data
      const ids = (selectedRow as any[]).filter(r => r.type === 'film').map(r => r._id);
      const episodes = ids?.length > 0 ? await searchEpisodes(ids) : ([] as IEpisode[]);
      const episodesDic = groupBy(episodes, 'series');

      const newTitleIds: string[] = [];
      const newTitles = selectedRow.map(r => {
        newTitleIds.push(r._id);
        return {
          order: 0,
          state: 'none',
          isDraft: true,
          thumbnail: rewriteImageUrl(selectImageUrl(r, episodesDic)),
          name: r.name.substring(0, 24),
          seriesType: r.type === 'tv' ? 'TV' : startCase(r.type),
          genre: r.genre,
          seriesCount: r.seasonCount.toString(),
          episodeCount: r.episodeCount.toString(),
          vodEntryId: r._id,
          published: r.published,
          ad_pods: 0,
          duration: 0,
          all_clips_ready: r.allClipsReady,
          has_unique_provider: r.hasUniqueProvider,
          availabilityWindows: (r.type === 'film' ? r.availabilityWindows?.AVOD : r.availabilityWindows?.linear).map(
            a => ({
              ...a,
              ...formatDateRange(a.startDate, a.endDate),
            }),
          ),
          contentId: r._id,
        } as IVodCategoryTitle;
      });

      const allEntriesFiltered = sortedVodEntries?.filter(e => !newTitleIds.includes(e.contentId));
      setSortedVodEntries([...newTitles, ...allEntriesFiltered!]);
      setSelectedItemAdded(newTitles.map(title => title.contentId));
      if (onAddTitles) {
        onAddTitles(newTitles);
      }
    } catch (err) {
      Toast.error('Error', (err as any).data.message);
    } finally {
      setIsAddTitleOpen(false);
    }
  };

  const handleAddFromCapstan = async (newRows: IVodCategoryTitle[]) => {
    try {
      const clonedRows = _.cloneDeep(newRows);
      clonedRows.forEach(item => {
        item.order = 0;
        item.isDraft = true;
        item.isCapstanEpisode = item.seriesType === 'Film';
      });
      setSortedVodEntries([...clonedRows, ...sortedVodEntries!]);
      setSelectedItemAdded(clonedRows.map(title => title.contentId));
      if (onAddTitles) {
        onAddTitles(clonedRows);
      }
    } catch (err) {
      Toast.error('Error', (err as any).data.message);
    }
  };

  const handleReplaceFromCapstan = async (newRows: IVodCategoryTitle[]) => {
    try {
      const clonedRows = _.cloneDeep(newRows);
      clonedRows.forEach(item => {
        item.order = 0;
        item.isDraft = true;
        item.isCapstanEpisode = item.seriesType === 'Film';
      });
      setSortedVodEntries([...clonedRows]);
      setSelectedItemAdded(clonedRows.map(title => title.contentId));
      if (onAddTitles) {
        onAddTitles(clonedRows);
      }
      setReplaceExisting(true);
    } catch (err) {
      Toast.error('Error', (err as any).data.message);
      setReplaceExisting(false);
    }
  };

  const openCatalogSearch = () => {
    setIsAddTitleOpen(true);
  };

  const handleSingleItemSelection = (row: any, selected: boolean) => {
    if (row?.isDraft) {
      const contentId = row.contentId as string;
      if (selected) {
        setSelectedDraftIds(selectedDraftIds.concat(contentId));
      } else {
        setSelectedDraftIds(selectedDraftIds.filter(id => id !== contentId));
      }
    }
  };

  const handleItemSelection = (vodEntries: IVodCategoryTitle[]) => {
    setSelectedRow(vodEntries);
    setIsRemoveAllowed(!!vodEntries.length);
    selectedMovieIds.clear();
    selectedSeriesIds.clear();
    vodEntries.forEach(entry => {
      if (entry?.isDraft) {
        setSelectedDraftIds(old => [...old, entry.contentId]);
        return;
      } //skip drafts
      if (entry.contentType === 'episode') selectedMovieIds.add(entry.contentId);
      else selectedSeriesIds.add(entry.contentId);
    });
  };

  const handleItemHighlighted = item => {
    setIsTitleDrawerOpen(curr => !curr);
    if (item.contentId == curHighlight?.contentId) return;
    setVodEntriesStateAndMessage(item.rowStateAndMessage);
    setCurHighlight({
      ageRange: item.ageRange,
      title: item.name,
      seasonCount: item.seriesCount,
      episodeCount: item.episodeCount,
      genre: item.genre,
      subGenre: item.subGenre,
      isDraft: item?.isDraft,
      category: item.category,
      subCategory: item.subCategory,
      availability: item.availabilityWindows,
      poster169: item.poster16_9,
      contentId: item.contentId,
      seriesType: item.seriesType,
      episodeSeriesId: item.episodeSeriesId,
    });
    if (item.contentType === 'episode') {
      setEntryScheduling({...entryScheduling, episodeId: item.contentId});
    } else {
      setEntryScheduling({...entryScheduling, seriesId: item.contentId});
    }
  };

  const handleRowStatus = item => {
    if (item.contentId === curHighlight?.contentId && isTitleDrawerOpen) return 'highlight';

    return item.state;
  };

  const updateVodEntries = async idsToRemove => {
    const updatedArray = (sortedVodEntries || []).filter(entry => !idsToRemove.includes(entry.contentId));
    const draftTitles: IVodCategoryTitle[] = [];
    const existingTitles: IVodCategoryTitle[] = [];
    updatedArray.map(v => {
      if (v?.isDraft) {
        draftTitles.push(v);
      } else {
        existingTitles.push(v);
      }
    });
    const sorted = existingTitles.slice().sort((a, b) => a.order - b.order);
    const ranked = existingTitles.map(v => sorted.findIndex(w => w.order === v.order) + 1);
    const mapped = existingTitles.map((v, i) => ({...v, order: ranked[i]}));
    setSortedVodEntries([...draftTitles, ...uniqBy(mapped, 'id')]);
  };

  const callBulkDelete = async (vodCategoryId: string, seriesIds: string[], episodeIds: string[]) => {
    const response = await deleteVODBulk({
      vodCategoryId,
      seriesIds,
      episodeIds,
    });

    if ('error' in response) {
      if ((response.error as any)?.data.error) {
        Toast.error('Error', `${(response.error as any)?.data.message}.`);
      } else {
        Toast.error('Error', `${response.error}.`);
      }
    } else {
      updateVodEntries([...selectedDraftIds, ...seriesIds, ...episodeIds]);
      fetchEntries(offset);
      setIsRemoveAllowed(false);
      setTimeout(() => {
        Toast.success('Success', 'Deletion successful');
      }, 2000);
    }
  };

  function renderColumnState(row: IVodCategoryTitle) {
    let popoverContent;

    if (!isEmpty(row.rowStateAndMessage)) {
      popoverContent = row?.rowStateAndMessage?.map((entry, index) => (
        <Cluster key={index} wrap={false} align='center' justify='start'>
          <Icon icon='warning' color={entry.state as any} size='large' space='xsmall' />
          <Paragraph>{entry.message}</Paragraph>
        </Cluster>
      ));
    }

    return (
      <Popover trigger='mouseenter' appendToBody={true}>
        <Template label='trigger'>
          <Icon icon='warning' color={row.singleItemStateAndMessage?.state as any} size='large' />
        </Template>
        <Template label='popover'>
          {!isEmpty(row.rowStateAndMessage) && (
            <Box background='charcoal' padding='medium'>
              <Stack space='small'>{popoverContent}</Stack>
            </Box>
          )}
        </Template>
      </Popover>
    );
  }

  const getProgramVodEntriesRowState = row => {
    const rowState = getVodEntriesItemState(row);

    /*
      if error within content then display error message only in UI.
      Show full list of error and warning in right hand side tray of each vod category entry item.
    */
    let rowIcon;
    const errorInVodEntryContent = rowState?.find(i => i.state === 'error');
    errorInVodEntryContent ? (rowIcon = errorInVodEntryContent) : (rowIcon = rowState![0]);

    row.state = !isEmpty(rowIcon) ? rowIcon.state : '';
    row.singleItemStateAndMessage = rowIcon;
    row.rowStateAndMessage = rowState;

    return {
      ...row,
    };
  };

  const dateRows = (availability: any | undefined): DateRange[] | undefined => {
    if (!availability) {
      return undefined;
    }

    return availability.map(dateRange => {
      return {
        startDate: formatDateWithTimezone(dateRange.startDate, 'yyyy-LL-dd, HH:mm:ss'),
        endDate: formatDateWithTimezone(dateRange.endDate, 'yyyy-LL-dd, HH:mm:ss'),
      };
    });
  };

  const handleUrlLink = row => {
    return row.contentType === 'episode'
      ? routes.paths.seriesEditEpisodesDetailsPage
          .replace(':id', row.episodeSeriesId)
          .replace(':episodeId', row.contentId || '')
      : routes.paths.seriesEditDetailsPage.replace(':id', row.contentId);
  };

  const handleDetailsLink =
    curHighlight?.seriesType === 'Film'
      ? routes.paths.seriesEditEpisodesDetailsPage
          .replace(':id', curHighlight?.episodeSeriesId || '')
          .replace(':episodeId', curHighlight?.contentId || '')
      : routes.paths.seriesEditDetailsPage.replace(':id', curHighlight?.contentId || '');

  const showCapstanButton = ableTo('VOD_CAPSTAN') && model.activeRegion?.toUpperCase() === 'US';

  return (
    <ContentBoxes layout='cover' coverScrolling={true}>
      <Template label='contentBoxesCover'>
        <ContentBox>
          <Template label='header'>
            <Cluster space='medium' justify='space-between' align='center'>
              <Cluster space='medium' align='flex-end'>
                <Heading level='h3' color='secondary'>
                  Titles
                </Heading>
                <Help>{vodEntries?.metadata.totalCount || 0} Items</Help>
              </Cluster>
              <Cluster align='center' space='medium'>
                <Help>Total Hours: {calculateTotalDuration(totalDuration)}</Help>
                <Popover
                  manualTrigger={true}
                  visible={isRemoveTitleMultipleOpen}
                  onClickOutside={() => setIsRemoveTitleMultipleOpen(false)}
                  maxWidth='22rem'
                >
                  <Template label='trigger'>
                    <Button
                      permission={permissions.VOD_EDIT}
                      state={isRemoveAllowed ? 'enabled' : 'disabled'}
                      icon='delete'
                      type='delete'
                      onClick={() =>
                        setIsRemoveTitleMultipleOpen(isRemoveTitleMultipleOpen => !isRemoveTitleMultipleOpen)
                      }
                    >
                      Remove
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message='Are you sure you want to remove the selected title(s)?'
                      cancelButtonFunction={() => setIsRemoveTitleMultipleOpen(false)}
                      proceedButtonFunction={async () => {
                        setIsRemoveTitleMultipleOpen(false);
                        handleRemove();
                      }}
                    />
                  </Template>
                </Popover>
                <Button
                  state={!model.id || sortedVodEntries?.length === 0 ? 'disabled' : ''}
                  permission={permissions.VOD_EDIT}
                  onClick={() => setIsOrderDialogOpen(true)}
                >
                  Reorder
                </Button>
                <Button permission={permissions.VOD_EDIT} type='primary' onClick={() => openCatalogSearch()}>
                  {showCapstanButton ? '+ Add from Pluto' : '+ Add'}
                </Button>
                {model.id && (
                  <ReorderDialog
                    vodCategoryId={model.id || ''}
                    isOrderDialogOpen={isOrderDialogOpen}
                    setIsOrderDialogOpen={setIsOrderDialogOpen}
                    setHasReordered={setHasReordered}
                  />
                )}
                {showCapstanButton && (
                  <Button permission={permissions.VOD_EDIT} type='primary' onClick={() => setIsCapstanDialogOpen(true)}>
                    + Add from Capstan
                  </Button>
                )}
                {model.id && (
                  <CapstanDialog
                    isDialogOpen={isCapstanDialogOpen}
                    setIsDialogOpen={setIsCapstanDialogOpen}
                    model={model}
                    onBlur={onBlur}
                    onChange={onChange}
                    setFields={setFields}
                    programDraftEntries={sortedVodEntries || []}
                    handleAddToDraft={rows => {
                      handleAddFromCapstan(rows as IVodCategoryTitle[]);
                    }}
                    handleReplaceToDraft={rows => {
                      handleReplaceFromCapstan(rows as IVodCategoryTitle[]);
                    }}
                  />
                )}
                <Dialog isOpen={isAddTitleOpen} onClose={() => setIsAddTitleOpen(false)} width='100%' height='100%'>
                  <Template label='header'>
                    <Heading level='h2'>Add Title for Active Region {model.activeRegion?.toUpperCase()}</Heading>
                  </Template>
                  <Template label='body'>
                    <SeriesList
                      actionsCol={false}
                      addNewSeries={false}
                      showFavoriteSearch={true}
                      showMixedWhenUnpublished={true}
                      openNameLinkInTab={true}
                      publishedState={true}
                      hideActiveRegions={true}
                      checkboxCol='multiple'
                      inModal={true}
                      isModalOpen={isAddTitleOpen}
                      searchRegion={model.activeRegion?.toUpperCase()}
                      seriesSearchParams={seriesSearchParams}
                      setSeriesSearchParams={setSeriesSearchParams}
                      type='addVodCollection'
                      vodCollectionId={model.id}
                      onSelect={rows => {
                        handleSelect(rows as ISeriesListResult[]);
                      }}
                    />
                  </Template>
                  <Template label='footer'>
                    <Cluster justify='space-between'>
                      <div></div>
                      <Cluster space='small'>
                        <Button ghost={true} onClick={() => setIsAddTitleOpen(false)}>
                          Cancel
                        </Button>
                        <Button
                          type='primary'
                          state={selectedRow.length > 0 ? '' : 'disabled'}
                          onClick={() => handleAddToCollection()}
                        >
                          Add to Collection
                        </Button>
                      </Cluster>
                    </Cluster>
                  </Template>
                </Dialog>
              </Cluster>
            </Cluster>
          </Template>
          <Template label='content'>
            <Table
              predicate='contentId'
              highlightable={true}
              emptyMsg='No content is programmed'
              loading={false}
              fixedHeader={true}
              wrapContent={true}
              lazyLoading={lazyLoading || !sortedVodEntries || isDeleting}
              onLazyLoad={onLazyLoad}
              highlightNewRows={sortedVodEntries?.filter(row => selectedItemAdded.includes(row.contentId))}
              onRowClick={row => handleItemHighlighted(row)}
              onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
              onSelect={handleItemSelection}
              selectLog={handleSingleItemSelection}
              selected={selectedRow}
              sortDir={sortDir}
              sortCol={sortCol}
              selectable='multiple'
              id='titles-list'
              cols={[
                {
                  label: 'Order',
                  sortable: true,
                  colWidth: '1.5rem',
                  transform: row => row.order,
                },
                {
                  label: '',
                  colWidth: '0.625rem',
                  zeroXPadding: true,
                  transform: row => renderColumnState(row),
                },
                {
                  label: 'Title',
                  sortable: true,
                  transform: row => (
                    <Cluster wrap={false} space='small' align='center'>
                      <ImageWrapper
                        backgroundColor='black'
                        width='2.5rem'
                        height='3.65rem'
                        src={row.thumbnail}
                        alt={row.name}
                      />
                      <TdLink title={row.name} row={row} target='_blank' url={handleUrlLink(row)} />
                    </Cluster>
                  ),
                  colMinWidth: '12.5rem',
                },
                {
                  label: 'Collections',
                  transform: row => row.collections,
                  sortable: true,
                  colMinWidth: '5.9375rem',
                },
                {
                  label: 'Series Type',
                  sortable: true,
                  transform: row => row.seriesType,
                  colMinWidth: '8.9375rem',
                },
                {
                  label: 'Genre',
                  colMinWidth: '7.5rem',
                  sortable: true,
                  transform: row => row.genre,
                },
                {
                  label: '# of S',
                  colMinWidth: '5.9375rem',
                  sortable: true,
                  transform: row => row.seriesCount,
                },
                {
                  label: '# of E',
                  colMinWidth: '5.9375rem',
                  sortable: true,
                  transform: row => row.episodeCount,
                },
                {
                  label: 'Availability Window',
                  colMinWidth: '13.5rem',
                  colWidth: '14rem',
                  transform: row =>
                    row?.availabilityWindows?.map(a => {
                      const dateEnd = new Date(a.endDate);
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      if (dateEnd >= today) {
                        return (
                          <Cluster key={`avod-${a.id}`} wrap={true} justify='space-around'>
                            <Box paddingY='xxxxsmall'>
                              <Center>{formatDateWithTimezone(a.startDate, 'LL/dd/yyyy')}</Center>
                            </Box>
                            <Box paddingY='xxxxsmall'>
                              <Center>-</Center>
                            </Box>
                            <Box paddingY='xxxxsmall'>
                              <Center>{formatDateWithTimezone(a.endDate, 'LL/dd/yyyy')}</Center>
                            </Box>
                          </Cluster>
                        );
                      }
                    }),
                },
                ...(ableTo('VOD_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={[]}
                            removeOption={true}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'delete':
                                  handleRemove(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<any>,
                    ]
                  : []),
              ]}
              rows={sortedVodEntries?.map(i => getProgramVodEntriesRowState(i))}
              rowStatus={handleRowStatus}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Clips</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no clips currently available.</Notification>
              </Template>
            </Table>
            <Drawer
              width='28.75rem'
              isOpen={isTitleDrawerOpen}
              onClose={() => {
                setIsTitleDrawerOpen(false);
                setTitleInfo('details');
              }}
            >
              <Box paddingY='large' paddingRight='medium' paddingLeft='medium'>
                <Stack space='large'>
                  <Heading level='h4'>{curHighlight?.title}</Heading>
                  <Divider color='graphite' />
                  <Cluster buttonGroup={true} justify='center'>
                    <Button active={titleInfo === 'details' ? true : false} onClick={() => setTitleInfo('details')}>
                      Details
                    </Button>
                    <Button
                      active={titleInfo === 'scheduling' ? true : false}
                      onClick={() => setTitleInfo('scheduling')}
                    >
                      Scheduling
                    </Button>
                  </Cluster>
                  {titleInfo === 'details' && (
                    <Stack space='xlarge'>
                      <ImageWrapper
                        backgroundColor='black'
                        // change the height here as the image height changes
                        height='232px'
                        src={curHighlight?.poster169 || undefined}
                        alt='nature image'
                      />
                      <Cluster
                        align='center'
                        justify='center'
                        space={{mobile: 'large', tablet: 'large', desktop: 'large', wide: 'xxlarge'}}
                      >
                        <Heading level='h4'>
                          <Icon
                            linkTarget='_blank'
                            href={handleDetailsLink}
                            icon='open'
                            color='primary'
                            hoverColor='primaryLight'
                            textColor='primary'
                            space='xxsmall'
                            textDecoration={false}
                          >
                            View Title
                          </Icon>
                        </Heading>
                        {CAN_EDIT && (
                          <Heading level='h4'>
                            <Popover
                              manualTrigger={true}
                              visible={isRemoveTitleOpen}
                              onClickOutside={() => setIsRemoveTitleOpen(false)}
                            >
                              <Template label='trigger'>
                                <Icon
                                  icon='delete'
                                  color='delete'
                                  textColor='delete'
                                  hoverColor='deleteLight'
                                  space='xxsmall'
                                  onClick={() => setIsRemoveTitleOpen(isRemoveTitleOpen => !isRemoveTitleOpen)}
                                >
                                  Remove Title
                                </Icon>
                              </Template>
                              <Template label='popover'>
                                <DeleteConfirmation
                                  message='Are you sure you want to remove this title?'
                                  cancelButtonFunction={() => setIsRemoveTitleOpen(false)}
                                  proceedButtonFunction={async () => {
                                    setIsRemoveTitleOpen(false);
                                    setIsTitleDrawerOpen(curr => !curr);
                                    setIsRemoveAllowed(false);
                                    handleRemove(curHighlight);
                                  }}
                                />
                              </Template>
                            </Popover>
                          </Heading>
                        )}
                      </Cluster>
                      <Stack space='large'>
                        <Box
                          background='slate'
                          borderTop={true}
                          borderSize='0.125rem'
                          borderColor='onyx'
                          paddingY={{mobile: 'medium', wide: 'large'}}
                          paddingX={{mobile: 'medium', wide: 'xlarge'}}
                        >
                          <Stack space='xlarge'>
                            <Heading level='h3' color='secondary'>
                              Information
                            </Heading>
                            <Grid gap='xxlarge' maxCols={2} minimum='3.5rem'>
                              <Stack space='small'>
                                <Label># of Seasons</Label>
                                <Copy text={curHighlight?.seasonCount?.toString() || ''} />
                              </Stack>
                              <Stack space='small'>
                                <Label># of Episodes</Label>
                                <Copy text={curHighlight?.episodeCount?.toString() || ''} />
                              </Stack>
                              <Stack space='small'>
                                <Label>Genre</Label>
                                <Copy text={curHighlight?.genre || ''} />
                              </Stack>
                              <Stack space='small'>
                                <Label>Sub Genre</Label>
                                <Copy text={curHighlight?.subGenre || ''} />
                              </Stack>
                              <Stack space='small'>
                                <Label>Category</Label>
                                <Copy text={curHighlight?.category || ''} />
                              </Stack>
                              <Stack space='small'>
                                <Label>Sub Category</Label>
                                <Copy text={curHighlight?.subCategory || ''} />
                              </Stack>
                              {curHighlight?.ageRange && (
                                <Stack space='small'>
                                  <Label>Age Range</Label>
                                  <Copy text={curHighlight?.ageRange || ''} />
                                </Stack>
                              )}
                            </Grid>
                          </Stack>
                        </Box>
                        <Box
                          background='slate'
                          borderTop={true}
                          borderSize='0.125rem'
                          borderColor='onyx'
                          paddingY={{mobile: 'medium', wide: 'large'}}
                          paddingX={{mobile: 'medium', wide: 'xlarge'}}
                        >
                          <Stack space='xlarge'>
                            <Heading level='h3' color='secondary'>
                              Availability Windows
                            </Heading>
                            <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                              <Help state='info'>
                                All dates are on PDT. Start date will be set at 00:00:00, and end date at 23:59:59.
                              </Help>
                            </Icon>
                            <Table
                              cols={[
                                {
                                  label: 'Start Date',
                                  field: 'startDate',
                                },
                                {
                                  label: 'End Date',
                                  field: 'endDate',
                                },
                              ]}
                              rows={dateRows(curHighlight?.availability)}
                            ></Table>
                          </Stack>
                        </Box>
                        <Box
                          background='slate'
                          borderTop={true}
                          borderSize='0.125rem'
                          borderColor='onyx'
                          paddingY={{mobile: 'medium', wide: 'large'}}
                          paddingX={{mobile: 'medium', wide: 'xlarge'}}
                        >
                          <Stack space='xlarge'>
                            <Heading level='h3' color='secondary'>
                              Warnings
                            </Heading>
                            {vodEntriesStateAndMessage.length ? (
                              vodEntriesStateAndMessage.map((item, index) => (
                                <Stack key={index} space='xxxsmall'>
                                  <Icon icon='warning' space='small' color={item.state as any}>
                                    {item.message}
                                  </Icon>
                                </Stack>
                              ))
                            ) : (
                              <Stack space='xxxsmall'>
                                <Icon icon='check' space='small' color='success'>
                                  No Warnings
                                </Icon>
                              </Stack>
                            )}
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                  {titleInfo === 'scheduling' && (
                    <Table
                      loading={isEpisodeFetching || isSeriesFetching}
                      loadingMsg='Loading Schudule Information'
                      wrapContent={true}
                      cols={[
                        {
                          label: 'Collection Name',
                          transform: row => (
                            <TdLink
                              title={row.collectionName}
                              row={row}
                              target='_blank'
                              url={vodCollectionRoutes.paths.vodCollectionEditDetailsPage.replace(
                                ':id',
                                row.vodCollectionId as string,
                              )}
                            />
                          ),
                        },
                        {
                          label: 'Published',
                          transform: row => (
                            <Status
                              state={row.published === 'Published' ? 'success' : 'neutral'}
                              label={row.published}
                            />
                          ),
                        },
                        {
                          label: 'Order',
                          field: 'order',
                        },
                      ]}
                      rows={curHighlight?.vodCatEntries}
                    ></Table>
                  )}
                </Stack>
              </Box>
            </Drawer>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};
