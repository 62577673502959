import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  ContentBox,
  ContentBoxes,
  Dialog,
  FormItem,
  Heading,
  Icon,
  Label,
  Template,
  Textarea,
  Toast,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import {CapstanTable} from './CapstanTable';
import {IVodCategoryTitle} from 'models/vodCategoryEntry';
import {useEffect, useState} from 'react';
import {QueryTemplatesDialog} from './QueryTemplatesDialog';
import {FiltersSection} from './FiltersSection';
import {useLazyFindQuery as useCategoryLazyFindQuery} from 'features/vodCategoryEntries/vodCategoryEntriesApi';
import {useLazyCapstanQuery} from 'features/capstan/capstanEntriesApi';
import {vodEntryMapper} from 'helpers/mapVodCategoryEntries';
import {ICapstanFilters} from './models/capstan';
import {IVodCollection} from 'models/vodCollections';
import {initialQuery} from './FieldsConfiguration/capstanFields';
import {formatQuery} from './QueryBuilder/helpers/queryBuilderHelper';

interface ICapstanDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  model: Partial<IVodCollection>;
  setFields: (partialModel: Partial<IVodCollection>) => void;
  onBlur: (fieldName: keyof IVodCollection, setVal?: boolean) => void;
  onChange: <K extends keyof IVodCollection>(fieldName: K, value: IVodCollection[K]) => void;
  handleAddToDraft: (rows: IVodCategoryTitle[]) => void;
  handleReplaceToDraft: (rows: IVodCategoryTitle[]) => void;
  programDraftEntries: IVodCategoryTitle[];
}

export const CapstanDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  model,
  onBlur,
  onChange,
  setFields,
  handleAddToDraft,
  handleReplaceToDraft,
  programDraftEntries,
}: ICapstanDialogProps): JSX.Element => {
  const {permissions} = useAppPermissions();
  const [queryFetchError, setQueryFetchError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getCategoryEntries, {data: categoryEntries}] = useCategoryLazyFindQuery();
  const [getEntries, result] = useLazyCapstanQuery();
  const isCapstanLoading = result.isLoading || result.isFetching;
  const capstanEntries = result.data;
  const isCapstanError = result.isError;
  const [sortedVodEntries, setSortedVodEntries] = React.useState<IVodCategoryTitle[]>([]);
  // Query builder configs
  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    if (isDialogOpen) {
      setQuery(initialQuery);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (model.id) {
      getCategoryEntries({
        id: model.id!,
        offset: 0,
        limit: 2000,
        sort: '',
      });
    }
  }, [getCategoryEntries, model.id]);

  useEffect(() => {
    if (isCapstanError) {
      Toast.error(
        'Error',
        'An error occurred in your submission. Please check syntax callback messaging for guidance.',
      );
      setSortedVodEntries([]);
    }
  }, [isCapstanError]);

  const isItemInCollection = row => {
    const isInCollection = !!categoryEntries?.data.documents?.find(
      i => i.series?.id === row.contentId || i.episode?.id === row.contentId || i.episode?.series?.id === row.contentId,
    );
    const isInDraft = !!programDraftEntries.find(i => i.id === row.id);
    return isInCollection || isInDraft;
  };

  const fetchCapstanEntries = React.useCallback(
    // Fetch entries with filters
    (filters: ICapstanFilters) => {
      getEntries(filters);
    },
    [getEntries],
  );

  useEffect(() => {
    const orderedTitles = capstanEntries?.data?.documents ? vodEntryMapper(capstanEntries?.data?.documents) : [];
    setSortedVodEntries(orderedTitles);
  }, [capstanEntries]);

  const onDialogClose = () => {
    setSortedVodEntries([]);
    setIsDialogOpen(false);
  };

  // Save generated query into the model
  useEffect(() => {
    setFields({
      capstanSyntax: formatQuery(query, 'json'),
    });
  }, [query, setFields]);

  return (
    <Dialog isOpen={isDialogOpen} onClose={() => onDialogClose()} width='100%' height='100%'>
      <Template label='header'>
        <Heading level='h2'>AMLG/Capstan</Heading>
      </Template>
      <Template label='body'>
        <ContentBoxes layout='stack'>
          <Box background='pewter'>
            <ContentBox title='Syntax Input'>
              <Cluster>
                <Box width='85%'>
                  <FormItem
                    state={queryFetchError !== '' ? 'warning' : ''}
                    onBlur={() => onBlur('capstanSyntax')}
                    permission={permissions.VOD_EDIT}
                  >
                    <Textarea
                      onChange={value => onChange('capstanSyntax', value)}
                      value={model.capstanSyntax}
                      id='capstanSyntax'
                      minHeight='12rem'
                    />
                  </FormItem>
                  {queryFetchError !== '' && (
                    <Box marginLeft='xxxxxxsmall' marginTop={'xxxxsmallNegative'}>
                      <Cluster align='center' space='xxsmall'>
                        <Icon icon='warning' color='warning' />
                        <Label state='warning'>{queryFetchError}</Label>
                      </Cluster>
                    </Box>
                  )}
                </Box>
                <Box marginTop='medium' marginLeft='small'>
                  <Icon
                    icon='copy'
                    onClick={() => {
                      navigator.clipboard.writeText(model.capstanSyntax || '');
                      Toast.success('Copied to clipboard.');
                    }}
                  />
                </Box>
                <Box marginTop='medium' marginLeft='small'>
                  <Button type='primary' onClick={() => setIsModalOpen(true)}>
                    + Query Templates
                  </Button>
                </Box>
              </Cluster>
            </ContentBox>

            <FiltersSection
              capstanSyntax={model.capstanSyntax || ''}
              isLoading={isCapstanLoading}
              setFields={setFields}
              setQueryFetchError={setQueryFetchError}
              onSubmit={fetchCapstanEntries}
            />
          </Box>
        </ContentBoxes>

        <Box marginTop='xxsmall'>
          <CapstanTable
            data={sortedVodEntries || []}
            isLoading={isCapstanLoading}
            isItemInCollection={isItemInCollection}
            handleAddToDraft={rows => {
              handleAddToDraft(rows as IVodCategoryTitle[]);
            }}
            handleReplaceToDraft={rows => {
              handleReplaceToDraft(rows as IVodCategoryTitle[]);
            }}
          />
        </Box>

        <QueryTemplatesDialog isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={() => onDialogClose()}>
              Close
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};
